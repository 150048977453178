import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaHome } from 'react-icons/fa';
import { motion, useAnimation } from 'framer-motion'; // Import motion from Framer Motion
import satellite from '../assets/satellite.png';

const Error = () => {
    const navigator = useNavigate();
    const sat = useAnimation();

    useEffect(() => {
      sat.start({
        rotate: 10,
        x: [0, 1100, 0],
        transition : {
          repeat: Infinity,
          duration: 30,
          ease: 'linear'
        }
      })
    },[sat])

    return (
        <div className='bg-stone-900 flex flex-col h-screen justify-center items-center gap-2 relative'>
            <div className='flex font-["Poppins"] text-white text-[150px] font-bold'>
                <p>4</p>
                <p>0</p>
                <p>4</p>
                <p>!</p>
            </div>

            <div>
                <button className='text-lg text-white bg-sky-600 px-3 py-1 font-["Poppins"] rounded-md flex justify-center items-center gap-2 hover:bg-sky-800' onClick={() => navigator('/')}>
                    <FaHome color='white' size={20} />
                    Home
                </button>
            </div>

            {/* Animate satellite component */}
            <motion.img
                src={satellite}
                alt="Satellite"
                className='absolute w-[180px] top-8 left-10 h-auto'
                animate={sat}
            />
        </div>
    );
}

export default Error;

