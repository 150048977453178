import axios from 'axios';

export const getCountryCodeFromLatLng = async (lat, lng) => {
  const url = `https://nominatim.openstreetmap.org/reverse?lat=${lat}&lon=${lng}&format=json`;
  try {
    const response = await axios.get(url);
    const countryCode = response.data.address.country_code.toUpperCase();
    const country = response.data.address.country.toUpperCase()
    return {countryCode, country};
  } catch (error) {
    console.error('Error fetching country code:', error.message);
    return 'Error';
  }
};