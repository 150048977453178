import React, { useContext, useEffect, useState } from 'react';
import Logo from '../assets/hsulogo.svg';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import cubesat_bw from '../assets/cubesat_bw.svg';
import cubesat_color from '../assets/cubesat_color.svg';
import cansat_color from '../assets/cansat_color.svg';
import cansat_bw from '../assets/cansat_bw.svg';
import globe from '../assets/earth.svg';
import { SocketContext } from '../context/SocketContext';
import { BiSupport } from "react-icons/bi";
import { Link } from 'react-router-dom';
import { IoMdDownload } from "react-icons/io";
import UserManual from '../utilities/User_Manual.pdf';
import { motion, useAnimation } from 'framer-motion';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import { IoThermometer, IoBarChart, IoCompass, IoWater, IoLocationSharp } from "react-icons/io5";
import { FaArrowUp, FaArrowDown } from "react-icons/fa";
import Earth from './Earth';
import { getCountryCodeFromLatLng } from '../utilities/helper'


const Home = () => {

  const [selectCubesat, setSelectCubesat] = useState(false);
  const [selectCansat, setSelectCansat] = useState(false);
  const [isHoveredCan, setIsHoveredCan] = useState(false);
  const [isHoveredCube, setIsHoveredCube] = useState(false);
  const socket = useContext(SocketContext);
  const animation = useAnimation();

  const [sensorDataQueue, setSensorDataQueue] = useState({
    temperature: [],
    pressure: [],
    altitude: [],
    accelerationX: [],
    accelerationY: [],
    accelerationZ: [],
    gyroX: [],
    gyroY: [],
    gyroZ: [],
    humidity: []
});
const [time, setTime] = useState(0);
const [latitude, setLatitude] = useState('');
const [longitude, setLongitude] = useState('');
const [countrycode, setCountryCode] = useState('');
const [country, setCountry] = useState('');
const now = new Date();
const formattedDate = now.toLocaleDateString();
const formattedTime = now.toLocaleTimeString();


useEffect(() => {
    if('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition((position) => {
            setLatitude(position.coords.latitude);
            setLongitude(position.coords.longitude);
        })
    }
},[latitude, longitude])

useEffect(() => {
    const fetchCountryCode = async () =>{
        const code = await getCountryCodeFromLatLng(latitude, longitude);
        setCountryCode(code.countryCode);
        setCountry(code.country);
    }
    fetchCountryCode();
})

const disconnectSocket = () => {
    if (socket) {
        socket.disconnect(); // Disconnect socket connection
        window.location.reload();
    }
};

// Function to update the queue with new data
const updateQueue = (queue, newData) => {
    if (queue.length === 10) {
        queue.shift(); // Remove oldest data if queue is full
    }
    return [...queue, newData]; // Add new data to the end of the queue
};


// Combine acce data for all axes into one dataset
const combinedAccelerationData = sensorDataQueue.accelerationX.map((valueX, index) => ({
    time: time - sensorDataQueue.accelerationX.length + index + 1,
    gyroX: valueX,
    gyroY: sensorDataQueue.accelerationY[index],
    gyroZ: sensorDataQueue.accelerationZ[index]
}));


// Combine gyro data for all axes into one dataset
const combinedGyroData = sensorDataQueue.gyroX.map((valueX, index) => ({
    time: time - sensorDataQueue.gyroX.length + index + 1,
    accelerationX: valueX,
    accelerationY: sensorDataQueue.gyroY[index],
    accelerationZ: sensorDataQueue.gyroZ[index]
}));

const lastHumidity = sensorDataQueue.humidity[sensorDataQueue.humidity.length - 1];
const secondLastHumidity = sensorDataQueue.humidity[sensorDataQueue.humidity.length - 2];
const humidityFluctuation = lastHumidity - secondLastHumidity;

const lastTemperature = sensorDataQueue.temperature[sensorDataQueue.temperature.length - 1];
const secondLastTemperature = sensorDataQueue.temperature[sensorDataQueue.temperature.length - 2];
const temperatureFluctuation = lastTemperature - secondLastTemperature;

const lastPressure = sensorDataQueue.pressure[sensorDataQueue.pressure.length - 1];
const secondLastPressure = sensorDataQueue.pressure[sensorDataQueue.pressure.length - 2];
const pressureFluctuation = lastPressure - secondLastPressure;

const lastAltitude = sensorDataQueue.altitude[sensorDataQueue.altitude.length - 1];
const secondLastAltitude = sensorDataQueue.altitude[sensorDataQueue.altitude.length - 2];
const altitudeFluctuation = lastAltitude - secondLastAltitude;

const downloadSensorData = () => {
    // Initialize CSV content with header
    let csvContent = 'Temperature,Pressure,Altitude,AccelerationX,AccelerationY,AccelerationZ,GyroX,GyroY,GyroZ,Humidity,Longitude,Latitude\n';
  
    // Append each data point to the CSV content
    for (let i = 0; i < sensorDataQueue.temperature.length; i++) {
        csvContent += `${sensorDataQueue.temperature[i]},`;
        csvContent += `${sensorDataQueue.pressure[i]},`;
        csvContent += `${sensorDataQueue.altitude[i]},`;
        csvContent += `${sensorDataQueue.accelerationX[i]},`;
        csvContent += `${sensorDataQueue.accelerationY[i]},`;
        csvContent += `${sensorDataQueue.accelerationZ[i]},`;
        csvContent += `${sensorDataQueue.gyroX[i]},`;
        csvContent += `${sensorDataQueue.gyroY[i]},`;
        csvContent += `${sensorDataQueue.gyroZ[i]},`;
        csvContent += `${sensorDataQueue.humidity[i]},`;
        csvContent += `${longitude},`; // Assuming longitude and latitude are updated elsewhere
        csvContent += `${latitude}\n`;  // Assuming longitude and latitude are updated elsewhere
    }
  
    // Create and trigger the download
    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `sensor_date_${formattedDate}_${formattedTime}.csv`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
};

  useEffect(() => {

    animation.start({
      y: [0,40,0],
      transition: {
        repeat: Infinity,
        duration: 3.5,
        ease: 'linear',
      }
    })
  },[animation])

  const handelShowPorts = async () => {
    try {
      if (!selectCansat && !selectCubesat) {
        toast.error('Select a satellite type!');
        return;
      }
  
      if ("serial" in navigator) {
        const port = await navigator.serial.requestPort();
        
        // Check if the user didn't select any port
        if (!port) {
          toast.error('No port selected!');
          return;
        }
  
        await port.open({ baudRate: 9600 });
        const reader = port.readable.getReader();
        
        let buffer = '';
        const element = document.getElementById('content');

// Inside your while loop for reading data
while (true) {
    const { value, done } = await reader.read();
    if (done) {
        reader.releaseLock();
        break;
    }
    // Append the received data to the buffer
    buffer += new TextDecoder().decode(value);
    
    // Split the buffer by newline characters to extract complete lines
    const lines = buffer.split('\n');
    
    // Process complete lines
    for (let i = 0; i < lines.length - 1; i++) {
        const line = lines[i];
        // Parse and process the line here
        // console.log(line);
        socket.emit('data', line);
        if(socket) {
            element.scrollIntoView();
        }
    }
    
    // Update the buffer with any remaining incomplete data
    buffer = lines[lines.length - 1];
}
      } else {
        toast.error('Serial API not supported in this browser!');
      }
    } catch (error) {
      toast.info(error.message);
    }
  };

  useEffect(() => {
        
    if (!socket) {
        console.log('No socket');
        return;
    }; // No path available, do nothing

    // Event listener for receiving sensor data from the server
    socket.on('sensorData', (data) => {
        // Update state variables with received data
        setTime(prevTime => prevTime + 1); // Increment time
        setSensorDataQueue(prevQueue => ({
            temperature: updateQueue(prevQueue.temperature, data.temperature),
            pressure: updateQueue(prevQueue.pressure, data.pressure),
            altitude: updateQueue(prevQueue.altitude, data.altitude),
            accelerationX: updateQueue(prevQueue.accelerationX, data.accelerationX),
            accelerationY: updateQueue(prevQueue.accelerationY, data.accelerationY),
            accelerationZ: updateQueue(prevQueue.accelerationZ, data.accelerationZ),
            gyroX: updateQueue(prevQueue.gyroX, data.gyroX),
            gyroY: updateQueue(prevQueue.gyroY, data.gyroY),
            gyroZ: updateQueue(prevQueue.gyroZ, data.gyroZ),
            humidity: updateQueue(prevQueue.humidity, data.humidity),
        }));
    });

    return () => {
        socket.disconnect(); // Cleanup socket connection on component unmount
    };
}, [socket]);
  

  const handelCubesatSelect = () => {
    setSelectCansat(false);
    setSelectCubesat(true);
    
  }

  const handelCansatSelect = () => {
    setSelectCubesat(false);
    setSelectCansat(true);
  }

  const handelDownload = () => {
    const link = document.createElement('a');
    link.href = UserManual;

    link.download = 'DashboardUserManual.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

  }

  return (
    <div className='h-full flex flex-col justify-center items-center bg-stone-900 gap-8'>
      <ToastContainer theme='dark' />
      <div className='flex gap-[72rem] items-center mt-0 absolute top-0 w-full h-18 '>
        <a href="https://hexstaruniverse.com"><img src={Logo} alt="Xexicon" className='m-4 w-[125px] cursor-pointer'/></a>
        <button className='flex justify-center h-8 items-center gap-2 bg-purple-700 rounded-md text-white font-["Poppins"] capitalize px-3 shadow-sm shadow-purple-400 shadow-opacity-10 hover:bg-purple-800' onClick={handelDownload}>
          <IoMdDownload color='white' size={20}></IoMdDownload>
          user manual
        </button>
      </div>
      
        <div className='flex gap-8 mt-[15vh]'>
          <div className={`relative w-[290px] h-[204px] bg-neutral-950 bg-opacity-0 rounded-md border-4 ${selectCubesat ? 'border-purple-600' : 'border-white'} flex flex-col justify-center items-center text-white text-xl font-normal font-['Aldrich'] hover:border-purple-600 transition-[0.5s] cursor-pointer`}
          onMouseEnter={() => setIsHoveredCube(true)}
          onMouseLeave={() => setIsHoveredCube(false)}
          onClick={handelCubesatSelect}
          >
            <img src={!isHoveredCube && !selectCubesat ? cubesat_bw : cubesat_color} alt="Cubesat" className='absolute bottom-[30%] w-[200px]' />
            <p className='absolute top-[80%]'><strong>Cubesat</strong></p>
          </div>
          <div className={`relative w-[290px] h-[204px] bg-neutral-950 bg-opacity-0 rounded-md border-4 ${selectCansat ? 'border-purple-600' : 'border-white'} flex flex-col justify-center items-center text-white text-xl font-normal font-['Aldrich'] hover:border-purple-600 transition-[0.5s] cursor-pointer`} 
          onMouseEnter={() => setIsHoveredCan(true)}
          onMouseLeave={() => setIsHoveredCan(false)}
          onClick={handelCansatSelect}
          >
            <img src={!isHoveredCan && !selectCansat ? cansat_bw : cansat_color} alt="Cansat" className='absolute bottom-[8%] w-[200px]' />
            <p className='absolute top-[80%]'><strong>Cansat</strong></p>
          </div>
        </div>

        <button className='capitalize bg-purple-700 font-normal text-lg text-white px-3 rounded-md py-1 font-["Poppins"] shadow-sm shadow-purple-400 shadow-opacity-10 hover:bg-purple-800 mt-5 hover:bg-purple-800 cursor-pointer' onClick={handelShowPorts}>Select Resouce</button>

        {/* <motion.div className='w-[500px] mt-12' animate={animation}>
          <img src={globe} alt="Earth" />
        </motion.div> */}

        <div className='flex flex-col h-screen w-full h-screen items-center justify-center -m-5'>
            <Earth /> 
            <div className='flex flex-col justify-center items-center text-white font-["Aldrich"]'>
            <img src={`https://flagsapi.com/${countrycode}/flat/64.png`} alt={countrycode} className='-mt-16'></img>
            <span>[ {country} ]</span>
            </div>   
        </div>

        <div className='flex justify-center items-center h-screen font-["Aldrich"] text-white flex-col mt-32 mb-20 gap-10'>
            <div className='text-white text-md'>
                <ul className='flex gap-10'>
                    <li className='flex gap-2'>
                        <div className='w-[18px] h-[18px] bg-red-600 rounded-sm'>

                        </div>
                        <p>X axis</p>
                    </li>
                    <li className='flex gap-2'>
                        <div className='w-[18px] h-[18px] bg-green-400 rounded-sm'>

                        </div>
                        <p>Y axis</p>
                    </li>
                    <li className='flex gap-2'>
                        <div className='w-[18px] h-[18px] bg-blue-500 rounded-sm'>

                        </div>
                        <p>Z axis</p>
                    </li>
                    <li className='flex gap-2'>
                        <div className='w-[28px] h-[18px] border-2 border-stone-600 rounded-sm'>
                            <div className={`flex justify-center items-center bg-stone-200 w-[50%] h-[10.5px] mt-[2.5px]`}></div>
                        </div>
                        <p>Power</p>
                    </li>
                </ul>
            </div>
            <div className='flex gap-10'  id='content'>
                <button className='capitalize bg-purple-600 rounded-md px-3 py-2 hover:bg-purple-800 transition-[0.3s] flex justify-center items-center gap-2' onClick={downloadSensorData}>
                    <IoMdDownload color='white' size={18} />
                    Download Data</button>
                <button className='capitalize bg-red-600 rounded-md px-3 py-2 hover:bg-red-700 transition-[0.3s]' onClick={disconnectSocket}>Disconnect</button>
            </div>
            {/* Display sensor data */}
            <div className='flex flex-col gap-6 mt-7'>
                <div className='flex flex-row gap-20'>
                    <div className='flex flex-col p-4 bg-stone-900 bg-opacity-60 rounded-[10px] shadow border border-neutral-500 backdrop-blur-[10px]'>
                        <p className='flex justify-center items-center font-bold text-xl'>Temperature</p>
                        <LineChart width={300} height={200} data={sensorDataQueue.temperature.map((temp, index) => ({ time: time - sensorDataQueue.temperature.length + index + 1, temperature: temp }))} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                            <XAxis dataKey="time" label={{ value: "Time", position: "insideBottom", dy: 10 }} stroke='white' />
                            <YAxis label={{ value: "Celcious", angle: -90, position: "insideLeft", dx: -10 }} domain={[25, 46]} stroke='white' />
                            <Tooltip />
                            <CartesianGrid stroke="#f5f5f5" strokeOpacity={0.1} />
                            <Line type='linear' dataKey="temperature" stroke="#FEF74C" strokeWidth={2} />
                        </LineChart>
                    </div>
                    <div className='flex flex-col p-4 bg-stone-900 bg-opacity-60 rounded-[10px] shadow border border-neutral-500 backdrop-blur-[10px]'>
                        <p className='flex justify-center items-center font-bold text-xl'>Altitude</p>
                        <LineChart width={300} height={200} data={sensorDataQueue.altitude.map((alt, index) => ({ time: time - sensorDataQueue.altitude.length + index + 1, altitude: alt }))} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                            <XAxis dataKey="time" label={{ value: "Time", position: "insideBottom", dy: 10 }} stroke='white' />
                            <YAxis
                                label={{ value: "Meter(s)", angle: -90, position: "insideLeft", dx: -10 }}
                                domain={[50, 80]}
                                stroke='white'
                            />
                            <Tooltip />
                            <CartesianGrid stroke="#f5f5f5" strokeOpacity={0.1} />
                            <Line type='linear' dataKey="altitude" stroke="#4CFE53" strokeWidth={2} />
                        </LineChart>
                    </div>
                    <div className='flex flex-col p-4 bg-stone-900 bg-opacity-60 rounded-[10px] shadow border border-neutral-500 backdrop-blur-[10px]'>
                        <p className='flex justify-center items-center font-bold text-xl'>Atmospheric Pressure</p>
                        <LineChart width={300} height={200} data={sensorDataQueue.pressure.map((psi, index) => ({ time: time - sensorDataQueue.pressure.length + index + 1, pressure: psi }))} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                            <XAxis dataKey="time" label={{ value: "Time", position: "insideBottom", dy: 10 }} stroke='white' />
                            <YAxis
                                label={{ value: "PSI", angle: -90, position: "insideLeft", dx: -10 }}
                                domain={[1000, 1006]}
                                stroke='white'
                            />
                            <Tooltip />
                            <CartesianGrid stroke="#f5f5f5" strokeOpacity={0.1} />
                            <Line type='linear' dataKey="pressure" stroke="#FEA14C" strokeWidth={2} />
                        </LineChart>
                    </div>
                </div>
                <div className='flex flex-row gap-20 mt-10'>
                    <div className='flex flex-col p-4 w-fit bg-stone-900 bg-opacity-60 rounded-[10px] shadow border border-neutral-500 backdrop-blur-[10px]'>
                        <p className='flex justify-center items-center font-bold text-xl'>Gyro</p>
                        <LineChart width={300} height={200} data={combinedAccelerationData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                            <XAxis dataKey="time" label={{ value: "Time", position: "insideBottom", dy: 10 }} stroke='white' />
                            <YAxis label={{ value: "Gyro (units)", angle: -90, position: "Left", dx: -45 }} domain={[2200, 20000]} stroke='white' />
                            <Tooltip />
                            <CartesianGrid stroke="#f5f5f5" strokeOpacity={0.1} />
                            <Line type='linear' dataKey="gyroX" stroke="#FF5733" strokeWidth={2} />
                            <Line type='linear' dataKey="gyroY" stroke="#33FFC1" strokeWidth={2} />
                            <Line type='linear' dataKey="gyroZ" stroke="#337DFF" strokeWidth={2} />
                        </LineChart>
                    </div>
                    <div className='flex flex-col p-4 w-[333px] bg-stone-900 bg-opacity-60 rounded-[10px] shadow border border-neutral-500 backdrop-blur-[10px] gap-3'>
                        <p className='flex justify-center items-center font-bold text-xl'>Data Telemetry</p>
                        <div className='flex flex-row capitalize text-[12px] gap-12 justify-center items-center text-zinc-400'>
                            <p>Data Name</p>
                            <p>Value</p>
                            <p>Fluctuation</p>
                        </div>
                        <div className='flex flex-row items-center capitalize text-[10px] gap-2 justify-start text-white'>
                            <IoThermometer size={18} />
                            <p>Temperature</p>
                            <p className="w-[90px] ml-8">{sensorDataQueue.temperature[sensorDataQueue.temperature.length - 1]}</p>
                            <p>{temperatureFluctuation >= 0 ? <FaArrowUp color='green'/> : <FaArrowDown color='red' />}</p>
                        </div>
                        <div className='flex flex-row items-center capitalize text-[10px] gap-2 justify-start text-white'>
                            <IoBarChart size={18} />
                            <p>Pressure</p>
                            <p className="w-[90px] ml-[50px]">{sensorDataQueue.pressure[sensorDataQueue.pressure.length - 1]}</p>
                            <p>{pressureFluctuation >= 0 ? <FaArrowUp color='green'/> : <FaArrowDown color='red' />}</p>
                        </div>
                        <div className='flex flex-row items-center capitalize text-[10px] gap-2 justify-start text-white'>
                            <IoCompass size={18} />
                            <p>Altitude</p>
                            <p className="w-[90px] ml-14">{sensorDataQueue.altitude[sensorDataQueue.altitude.length - 1]}</p>
                            <p>{altitudeFluctuation >= 0 ? <FaArrowUp color='green'/> : <FaArrowDown color='red' />}</p>
                        </div>
                        <div className='flex flex-row items-center capitalize text-[10px] gap-2 justify-start text-white'>
                            <IoWater size={18} />
                            <p>Humidity</p>
                            <p className="w-[90px] ml-[52px]">{sensorDataQueue.humidity[sensorDataQueue.humidity.length - 1]}</p>
                            <p>{humidityFluctuation >= 0 ? <FaArrowUp color='green'/> : <FaArrowDown color='red' />}</p>
                        </div>
                        <div className='flex flex-row items-center capitalize text-[10px] gap-2 justify-start text-white'>
                            <IoLocationSharp size={18} />
                            <p>Longitude</p>
                            <p className="w-[90px] ml-12">{parseFloat(longitude).toFixed(5)}</p>
                        </div>
                        <div className='flex flex-row items-center capitalize text-[10px] gap-2 justify-start text-white'>
                            <IoLocationSharp size={18} />
                            <p>Latitude</p>
                            <p className="w-[90px] ml-14">{parseFloat(latitude).toFixed(5)}</p>
                    </div>
                </div>
                <div className='flex flex-col p-4 w-fit bg-stone-900 bg-opacity-60 rounded-[10px] shadow border border-neutral-500 backdrop-blur-[10px]'>
                        <p className='flex justify-center items-center font-bold text-xl'>Acceleration</p>
                        <LineChart width={300} height={200} data={combinedGyroData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                            <XAxis dataKey="time" label={{ value: "Time", position: "insideBottom", dy: 10 }} stroke='white' />
                            <YAxis label={{ value: "Acceleration (m/s²)", angle: -90, position: "Left", dx: -45 }} domain={[-150, 20000]} stroke='white' />
                            <Tooltip />
                            <CartesianGrid stroke="#f5f5f5" strokeOpacity={0.1} />
                            <Line type='linear' dataKey="accelerationX" stroke="#FF5733" strokeWidth={2} />
                            <Line type='linear' dataKey="accelerationY" stroke="#33FFC1" strokeWidth={2} />
                            <Line type='linear' dataKey="accelerationZ" stroke="#337DFF" strokeWidth={2} />
                        </LineChart>
                    </div>
                </div>
            </div>
            </div>

        <Link to='https://wa.link/e1vw9c'>
        <button className='fixed bottom-10 right-10 bg-purple-600 rounded-[50%] p-3 hover:translate-x-[-3px] hover:bg-purple-800'>
            <BiSupport  color='white' size={30} /></button>
        </Link>

        <div className='flex flex-col text-stone-400 mb-10 font-["Poppins"] text-[12px] text-center'>
            
            <span>© 2024 Hex-Star Universe | Website developed & designed by <span className='underline'><a href="https://www.hexstaruniverse.com">Hex-Star Universe</a></span></span>
            <span className='text-[12px]'>country_code & country provided by <a href="https://www.openstreetmap.org/" className='underline'>OpenStreetMap</a></span>
            <span className='text-[12px]'>Flags provided by <a href="https://flagsapi.com/" className='underline'>COUNTRYFLAGS</a></span>

        </div>
        
       
    </div>
  );
}

export default Home;