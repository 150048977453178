import React, { createContext, useState, useEffect } from 'react';
import io from 'socket.io-client'; // Import for socket.io client

export const SocketContext = createContext(null);

export const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    const initializedSocket = createSocketConnection();
    setSocket(initializedSocket);

    // Clean up function to close the socket on unmount
    return () => initializedSocket.disconnect();
  }, []);

  return (
    <SocketContext.Provider value={socket}>
      {children}
    </SocketContext.Provider>
  );
};

function createSocketConnection() {
  const SERVER_URL = 'https://sensor-data-ui.onrender.com'; // Replace with your server URL
  const initializedSocket = io(SERVER_URL);

  // Optional event listeners for the socket (example)
  initializedSocket.on('connect', () => {
    // console.log('Connected to server');
  });

  initializedSocket.on('disconnect', () => {
    // console.log('Disconnected from server');
  });

  return initializedSocket;
}
