import React from 'react';
import { TfiFaceSad } from "react-icons/tfi";

const AccessDeny = () => {
  return (
    <div className='h-screen flex flex-col justify-center items-center text-white font-["Poppins"] font-regular text-sm gap-1'>
        <TfiFaceSad color='#facc15' size={100} className='mb-5' />
      <h1 className='text-[18px] font-semibold'>Access Denied !</h1>
      <p className='text-[12px]'>This website is accessible from computer only.</p>
    </div>
  );
}

export default AccessDeny;
